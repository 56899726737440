import React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"

export default () => (
  <Layout>
    <Link to="/">Home</Link>
    <p>This is contact page.</p>
  </Layout>
)
